import React, {useState, useContext} from 'react'

import ajaxRequest from '../../assets/functions/ajaxRequest.js'

import Context from './context.jsx'

import LoadingSpinner from '../shared/utility/loadingSpinner.jsx'

export default function LoginForm() {
  const {authenticityToken, buttonTextColor} = useContext(Context)

  const [email, setEmail] = useState('')
  const [linkSent, setLinkSent] = useState(false)
  const [linkSending, setLinkSending] = useState(false)

  function handleSendLoginEmail(event) {
    event.preventDefault()
    setLinkSending(true)

    ajaxRequest('POST', '/send_login_link', {
      params: {email},
      headers: [{'X-CSRF-Token': authenticityToken}],
    })
      .then((response) => {
        setLinkSending(false)
        setLinkSent(true)
      })
      .catch((response) => {
        setLinkSending(false)
        alert('Oops! Something went wrong.')
      })
  }

  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      {linkSent ? (
        <>
          <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>
            Check your email
          </h3>
          <p className='text-sm text-center'>
            We sent a link to log in to your account to{' '}
            <span className='font-bold'>{email}</span>
          </p>
        </>
      ) : (
        <>
          <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>
            Login or create new account
          </h3>
          <p className='mb-4 text-sm text-center'>
            We will email you a link to login to your account
          </p>

          <form onSubmit={handleSendLoginEmail} className='xs:w-1/2 mx-auto'>
            <label
              htmlFor='email'
              className='block mb-1 text-left text-lg md:text-xl uppercase font-bold'>
              Email
            </label>

            <input
              className='block w-full p-2 mb-4 border-2 border-dark text-xl font-normal'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              type='text'
              id='email'
            />

            {linkSending ? (
              <LoadingSpinner
                size='12'
                color='text-primary'
                extraClasses='mx-auto'
              />
            ) : (
              <button
                className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
                Send link
              </button>
            )}
          </form>
        </>
      )}
    </div>
  )
}
