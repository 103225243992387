import React, {useLayoutEffect, useContext} from 'react'
import {useParams, Link, Redirect} from 'react-router-dom'
import moment from 'moment'

import Context from './context.jsx'

import LoginForm from './loginForm.jsx'
import RedeemForm from './redeemForm.jsx'
import usePrerenderDetection from '../../hooks/usePrerenderDetection'
import LoadingSpinner from '../shared/utility/loadingSpinner.jsx'

export default function OfferPage({setParallaxImage}) {
  const {
    brand,
    consumer,
    authenticated,
    offers,
    initialOffersLoaded,
    consumerLimitReachedByOffer,
    buttonTextColor,
    gaEvent,
    selectedState,
  } = useContext(Context)

  const {id} = useParams()
  const offer = offers.find((offer) => offer.id === Number(id))

  const {browserIsPrerender, setPrerenderStatusCode} = usePrerenderDetection()

  useLayoutEffect(() => {
    if (brand && offer)
      gaEvent(brand.name, 'View Offer', `${offer.name} (${offer.id})`, offer.id)
    setParallaxImage(offer ? offer.image_url : false)
    return () => setParallaxImage(false)
  }, [brand, offer])

  if (initialOffersLoaded && !offer) {
    // the offer was not found
    setPrerenderStatusCode(404)
    return (
      <div className='flex justify-center items-center h-screen'>
        <div className='container w-full lgplus:w-3/5 py-2 md:py-4 px-4 text-center text-dark'>
          <div className='mx-auto'>
            <OfferNotFoundMessage
              buttonTextColor={buttonTextColor}
              selectedState={selectedState}
            />
          </div>
        </div>
      </div>
    )
  }

  if (!offer)
    return (
      <div className='flex justify-center items-center h-screen'>
        <div className='container w-full lgplus:w-3/5 py-2 md:py-4 px-4 text-center text-dark'>
          <div className='mx-auto'>
            <LoadingSpinner
              size='12'
              color='text-primary'
              extraClasses='mx-auto'
            />
          </div>
        </div>
      </div>
    )

  return (
    <div className='container w-full lgplus:w-3/5 mx-auto py-2 md:py-4 px-4 text-center text-dark'>
      <div className='mx-auto mb-8'>
        <h1 className='mb-1 text-3xl md:text-4xl uppercase font-bold'>
          {offer.name}
        </h1>
        <p className='mb-1 text-sm'>{offer.details}</p>
        <div className='text-sm uppercase'>
          {offer.per_consumer_limit} Per Household. Ends{' '}
          {moment.utc(offer.end_date).format('MM.DD.YYYY')}
        </div>
      </div>

      {authenticated ? (
        consumer.disabled ? (
          <DisabledAccountMessage buttonTextColor={buttonTextColor} />
        ) : consumerLimitReachedByOffer[offer.id] ? (
          <PerConsumerLimitReachedMessage buttonTextColor={buttonTextColor} />
        ) : (
          <RedeemForm offer={offer} />
        )
      ) : (
        <LoginForm />
      )}

      <p className='mx-auto text-2xs text-gray-500'>
        Offer valid from {moment.utc(offer.start_date).format('MM-DD-YYYY')} –{' '}
        {moment.utc(offer.end_date).format('MM-DD-YYYY')}. <br />
        {offer.conditions}
        <br />
        Offer ID: {offer.id}.
      </p>
    </div>
  )
}

function PerConsumerLimitReachedMessage({buttonTextColor}) {
  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>
        Household limit reached
      </h3>
      <p className='mb-4 text-sm text-center'>
        Return to the offers page to see more offers
      </p>
      <Link
        to='/offers'
        className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
        View offers
      </Link>
    </div>
  )
}

function DisabledAccountMessage({buttonTextColor}) {
  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>
        Your Account Has been Disabled
      </h3>
      <p className='mb-4 text-sm text-center'>
        Please go to your account page for more details
      </p>
      <Link
        to='/account'
        className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
        View Account
      </Link>
    </div>
  )
}

function OfferNotFoundMessage({buttonTextColor, selectedState}) {
  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>{`Offer no longer available or not available in ${selectedState.name}`}</h3>
      <p className='mb-4 text-sm text-center'>
        Please go to home page to see available offers
      </p>
      <Link
        to='/'
        className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
        Browse All Current Offers
      </Link>
    </div>
  )
}
